import Axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

function AgendaTurnos() {
  const clientId = process.env.REACT_APP_GCI
  const [data, setData] = useState({});
  const [token, setToken] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GCID,
        scope: "openid email profile https://www.googleapis.com/auth/calendar",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  // data.name: nombreCompleto,
  // data.givenName: nombre,
  // data.familyName: apellido,
  // data.email: email,
  // data.googleId: googleId,
  // token: accessToken

  const onSuccess = (response) => {
    setSignedIn(true);
    setData(response.profileObj);
    setToken(response.accessToken);
    console.log("success:", response);
    document.getElementsByClassName("profile").hidden = true;

		// nombre: nombre,
		// apellido: apellido,
		// fechaNacimiento: fechaNacimiento,
		// mail: mail,
		// password: password,
		// estado: true

		// Nombre: {data.givenName} <br />
		// Apellido: {data.familyName} <br />
		// N-Completo: {data.name} <br />
		// Mail: {data.email} <br />
		// GoogleID: {data.googleId} <br />

		const nombre = data.givenName;
		const apellido = data.familyName;
		const fechaNacimiento = '1990-06-18';
		const mail = data.email;
		const password = 'asd';
		const estado = true;


    Axios.post("http://localhost:8080/api/register/pacientes", {
			nombre,
			apellido,
			fechaNacimiento,
			mail,
			password,
			estado,		
    })
      .then(function () {
        // window.location.replace("../Navbar/Home");
        console.log("Paso por Axios" + response.profileObj);
      })
      .catch(function () {
        console.log("Reboto el Endpoint");
      });
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const cerrarSesionUsuarioGoogle = () => {
    setSignedIn(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(summary, description, location, startDateTime, endDateTime);

    /*
	    Axios.post('http://localhost:8080/api/crearEvento', {
			 summary,
			 description,
		   location,
			 startDateTime,
			 endDateTime,
    }).then(function () {
        window.location.replace("../Navbar/Agenda");
      }).catch(function () {
        Toast.fire({
          icon: 'error',
          title: 'No se puso registrar el turno'
        })
      });
###########################################################################################

Insert desde el servidor / Lo que espera Google para el insert

requestBody: {
	summary,
	description,
	location,
  colorId: '1',
		start: {
			dateTime: newDate(startDateTime),
		},
		end: {
			endDateTime
		},
}

		*/
  };

  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [signedIn, setSignedIn] = useState(false);

  //Para crear el evento
  const REFRESH_TOKEN = "";
  const GOOGLE_CLIENT_ID =process.env.REACT_APP_GCID;
  const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GCSE;


  // function crearEvento() {
  // 	const {summary, description, location, startDateTime, endDateTime} = result.response;
  // 	oauth2Client.setCredentials({refresh_token: REFRESH_TOKEN})
  // 	const calendar = google.calendar('v3');
  // 	const response = calendar .events.insert({
  // 		auth:oauth2Client,
  // 		calendarId: 'primary',
  // 		requestBody: {
  // 			summary,
  // 			description,
  // 			location,
  // 			colorId: '1',
  // 				start: {
  // 					dateTime: startDateTime,
  // 				},
  // 				end: {
  // 					endDateTime
  // 				}
  // 			}
  // 	})
  // 	result.send(response);
  // }

  return (
    <>
      <div className="googleEvents">{/* <p>{token}</p> */}</div>

      <div className="my-5 "></div>
      <div className="googleCambiarUsuario">
        <br />
        <GoogleLogin
          clientId={process.env.REACT_APP_GCID}
          buttonText="Cambiar de Usuario"
          onSuccess={onSuccess}
          onFailure={onFailure}
          // responseType="code"
          accessType="offline"
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
          scope="openid email profile https://www.googleapis.com/auth/calendar"
        />
      </div>
      <div className="my-5"></div>

      {!signedIn ? (
        <div className="container googleEvents">
          <div className="my-5 col">
            <br />
            <GoogleLogin
              clientId={process.env.REACT_APP_GCID}
              buttonText="Iniciar con Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              // responseType="code"
              accessType="offline"
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}
              scope="openid email profile https://www.googleapis.com/auth/calendar"
            />
          </div>
          <div className="my-5"></div>
        </div>
      ) : (
        <div className="googleEvents">
          <Alert variant="primary">
            <h3>Perfil de Google</h3> <br />
            <img src={data.imageUrl} alt="" /> <br />
            {/* Url: {data.imageUrl} <br /> */}
            Nombre: {data.givenName} <br />
            Apellido: {data.familyName} <br />
            N-Completo: {data.name} <br />
            Mail: {data.email} <br />
            GoogleID: {data.googleId} <br />
          </Alert>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="my-3"></div>
            <br />
            <Box
              className="container"
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="row">
                <TextField
                  label="Motivo"
                  variant="filled"
                  type="text"
                  id="summary"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
                <TextField
                  label="Descripcion"
                  // placeholder="Descripcion"
                  variant="filled"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                  label="Ubicacion"
                  variant="filled"
                  type="text"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <TextField
                  type="datetime-local"
                  variant="filled"
                  helperText="Inicio"
                  id="startDateTime"
                  value={startDateTime}
                  onChange={(e) => setStartDateTime(e.target.value)}
                />
                <TextField
                  type="datetime-local"
                  variant="filled"
                  helperText="Fin"
                  id="endDateTime"
                  value={endDateTime}
                  onChange={(e) => setEndDateTime(e.target.value)}
                />
              </div>
            </Box>
            <Button variant="outlined" type="submit">
              Crear Evento
            </Button>
          </form>
        </div>
      )}
    </>
  );
}

export default AgendaTurnos;

// console.log("googleId", response.profileObj.googleId);
// console.log("accessToken", response.accessToken);
